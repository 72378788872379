import _ from 'lodash';
export default {
    methods: {
        /**
         * @param {array} dataArray {string} name 
         * Pluck array items to string
         */
        limitSize(string, size) {
            const retString = _.truncate(string, {
                'length': size
            });
            return retString;
        },
        /**
         * @param {json} item
         * Parse json to array
         */
        parseJson(item) {
            const form = JSON.parse(item);
            const items = [];
            for (let i = form.length - 1; i >= 0; i--) {
                items.push(JSON.parse(form[i]));
            }
            return items.reverse();
        },
        /**
         * @param {string} str
         * Convert snake to camelCase
         */
        snakeToCamelCase(str) {
            return str.split('_').map(function(item) {
                return item.charAt(0).toUpperCase() + item.substring(1);
            }).join(' ');
        },

        /**
         * @param {string} str
         * Convert camelCase to snake
         */
        camelToSnakeCase(str) {
            return _.snakeCase(str);
        },
    }
};
