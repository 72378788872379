<template>
  <v-container class="pa-6" fluid>
    <v-row v-if="!showDataOnly" align="center" no-gutters
    >
      <v-col align="start" cols="12" md="6"
      >
        <h2>
          <v-icon color="primary" large left>{{ icon }}
          </v-icon
          >
          {{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col
      >
      <v-col align="end" cols="12" md="6">
        <v-btn
            v-if="userCan('create-' + [routeName])"
            :to="{ name: 'create-' + [routeName] }"
            class="primary--text"
            large
        >Add new
          <v-icon color="primary" right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row
    >
    <v-data-iterator
        :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
        :hide-default-footer="showDataOnly"
        :items="items"
        :items-per-page.sync="pagination.rowsPerPage"
        :loading="loading"
        :options="pagination"
        :page.sync="pagination.page"
        :server-items-length.sync="totalCount"
    >
      <template v-if="!showDataOnly" v-slot:header>
        <v-toolbar class="mb-1 my-4 " color="primary " dark>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
                v-model="pagination.search"
                class="mx-4"
                clearable
                flat
                hide-details
                label="Search"
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                solo-inverted
            ></v-text-field>
          </v-col>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" sm="6" md="3">
              <v-select
                  v-model="pagination.orderBy"
                  :items="keysForSorting"
                  class="mx-4"
                  flat
                  hide-details
                  label="Sort by"
                  placeholder="Sort by"
                  prepend-inner-icon="mdi-sort"
                  solo-inverted
              ></v-select>
            </v-col>
            <v-col v-if="namespace==='users'" cols="12" sm="6" md="2">
              <v-btn class="mx-3" large depressed outlined dark
                     @click="filterSelected=!filterSelected">
                <v-icon left>mdi-filter-variant</v-icon>
                Filters
              </v-btn>
            </v-col>
            <v-col v-if="namespace==='users'" cols="12" sm="6" md="2">
              <v-btn v-if="filterSelected" large class="mx-3" depressed outlined dark
                     @click="resetFilters">
                <v-icon left>mdi-filter-remove-outline</v-icon>
                Reset filters
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="pagination.sortDesc" mandatory>
              <v-btn :value="false" color="primary" depressed large>
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn :value="true" color="primary" depressed large>
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-toolbar dark color="primary" class="mb-1 my-4" v-if="filterSelected">
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                :items="hrCompanies"
                v-model="pagination.companies"
                label="HR Supplier"
                item-text="name"
                item-value="id"
                multiple
                clearable
                name="HR Supplier"
                hide-details
                solo
                flat
                solo-inverted
                dark
                class="font-weight-medium "
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" color="primary">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span
                    v-if="index === 1"
                    class="text-caption"
                    color="primary"
                >
                  (+{{ pagination.company_id.length - 1 }} others)
              </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-row class="flex-nowrap ml-3">
              <v-subheader>Created date from</v-subheader>
              <v-menu
                  ref="menuFrom"
                  :return-value.sync="pagination.dateFrom"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      append-icon="mdi-calendar"
                      :value="formatDate(pagination.dateFrom)"
                      hide-details
                      dark
                      flat
                      solo-inverted
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    first-day-of-week="1"
                    :ref="'picker_pagination.dateFrom'"
                    min="1950-01-01"
                    v-model="pagination.dateFrom"
                    no-title
                    flat
                    @input="$refs.menuFrom.save(pagination.dateFrom)"
                >
                </v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-row class="flex-nowrap ml-3">
              <v-subheader>Created date to</v-subheader>
              <v-menu
                  ref="menuTo"
                  :return-value.sync="pagination.dateTo"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      append-icon="mdi-calendar"
                      :value="formatDate(pagination.dateTo)"
                      hide-details
                      dark
                      flat
                      solo-inverted
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    first-day-of-week="1"
                    :ref="'picker_pagination.dateTo'"
                    min="1950-01-01"
                    v-model="pagination.dateTo"
                    no-title
                    @input="$refs.menuTo.save(pagination.dateTo)"
                >
                </v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
        </v-toolbar>

      </template>
      >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
          >
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar
          >
          <v-alert
              border="bottom"
              color="primary"
              colored-border
              elevation="2"
              type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
          >
            <v-toolbar-title>Loading data...</v-toolbar-title>
          </v-toolbar
          >
          <v-alert border="bottom" color="primary" colored-border elevation="2">
            <v-progress-circular color="primary" indeterminate/>
          </v-alert>
        </v-card>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
              v-for="item in props.items"
              :key="item.name"
              class="pa-4"
              cols="12"
              lg="4"
              md="4"
              sm="6"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card :class="{ 'on-hover': hover }" class="dekra-card">
                <v-card-title class="subheading font-weight-bold">
                  <v-row align="center">
                    <v-col v-if="item.logo_preview" cols="2">
                      <v-avatar class="pr-2" size="56" tile
                      >
                        <v-img :src="item.logo_preview" contain></v-img
                        >
                      </v-avatar>
                    </v-col>
                    <v-col
                        :cols="item.logo_preview ? 8 : 10"
                        class="text-left text-truncate"
                    >
                      <!--  <span class="body-3 pr-2">#{{ item["id"] }}</span>-->
                      <span>{{ limitSize(item[keyTitle], 22) }}</span></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-if="show && userCan('show-' + [routeName])"
                              v-bind="attrs"
                              v-on="on"
                              :to="{
                              name: 'show-' + [routeName],
                              params: { id: item.id }
                            }"
                              icon
                              large
                          >
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>View</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                      v-for="(key, index) in filteredKeys"
                      :key="index"
                  >
                    <v-list-item-content
                        v-if="key"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                    >{{ key.text }}:
                    </v-list-item-content
                    >
                    <v-list-item-content
                        v-if="key"
                        :class="{
                        'primary--text': pagination.sortBy === key.value
                      }"
                        class="align-end"
                    >
                      <span v-if="key.date">{{
                          formatDate(item[key.value])
                        }}</span>
                      <span v-else>{{
                          limitSize(getItemValue(item, key.value), 22)
                        }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <div v-if="item['created_at']" class="caption mx-2">
                    {{ formatDate(item["created_at"]) }}
                  </div>
                  <div v-if="item['date']" class="caption mx-2">
                    {{ formatDate(item["date"]) }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="showActivityButton && userCan('list-access-log')"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          large
                          @click="
                          activityDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon right>mdi-timeline-clock</v-icon>
                      </v-btn>
                    </template>
                    <span>Activity log</span>
                  </v-tooltip>
                  <v-btn
                      v-if="showCommentsButton"
                      class="primary--text"
                      icon
                      large
                  >
                    <v-icon color="primary">mdi-comment-text-multiple</v-icon>
                  </v-btn>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="userCan('edit-' + [routeName])"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                          name: 'edit-' + [routeName],
                          params: { id: item.id }
                        }"
                          icon
                          large
                      >
                        <v-icon right>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="userCan('delete-' + [routeName])"
                          v-bind="attrs"
                          v-on="on"
                          class="error--text"
                          icon
                          large
                          @click="
                          confirmDeleteDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon color="error" right
                        >mdi-trash-can-outline
                        </v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="confirmDeleteDialog" max-width="310" persistent>
      <v-card>
        <v-card-title class="error headline white--text"
        >Confirm Delete
        </v-card-title
        >
        <v-card-text class="body-1 pa-3"
        >Are you sure you want to delete this item?
        </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              large
              outlined
              text
              @click.native="confirmDeleteDialog = false"
          >
            <v-icon left size="20">mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn color="error" large outlined @click.native="deleteItem">
            <v-icon left size="20">mdi-trash-can-outline</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <activity-log-dialog
        v-if="activityDialog"
        :color="color"
        :dialog.sync="activityDialog"
        :id.sync="currentId"
        :namespace.sync="namespace"
        @dialog-cancel="updateActivityDialog(false)"
        @dialog-update="updateActivityDialog"
    ></activity-log-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import string from "@/mixins/string";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  mixins: [date, permission, string],
  components: {
    ActivityLogDialog: () =>
        import("@/components/core/dialogs/ActivityLogDialog")
  },
  name: "SimpleList",
  props: {
    names: {
      type: Array,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    keyTitle: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    goTo: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    showCommentsButton: {
      type: Boolean,
      default: false
    },
    showActivityButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      activityDialog: false,
      currentId: null,
      workforceId: null,
      itemsPerPageOptions: [12, 24, 48, -1],
      filterSelected: false
    };
  },
  async mounted() {
    await this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: 'hr-suppliers'
    });
  },
  computed: {
    ...mapGetters({
      hrCompanies: "companies/hrSuppliers"
    }),
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    },
    filteredKeys() {
      return this.names.filter(key => key.value !== this.keyTitle);
    },
    keysForSorting() {
      return this.names.filter(key => key.sortable == true);
    }
  },
  created() {
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    resetFilters() {
      this.pagination.companies = null;
      this.pagination.dateFrom = null;
      this.pagination.dateTo = null;
    },
    updateActivityDialog(dialog) {
      this.activityDialog = dialog;
    },
    deleteItem() {
      const _this = this;
      _this.$store
          .dispatch(_this.namespace + "/deleteItem", this.currentId)
          .then(() => {
            this.confirmDeleteDialog = false;
            this.currentId = null;
            this.getData();
          });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        if (item[nameArray[0]] != null && !_.isEmpty(item[nameArray[0]])) {
          if (
              nameArray[2] &&
              typeof item[nameArray[0]][nameArray[1]] !== "undefined"
          ) {
            return item[nameArray[0]][nameArray[1]][nameArray[2]];
          }
          return item[nameArray[0]][nameArray[1]];
        } else {
          return "N/A";
        }
      }
      return item[name];
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
  transition: transform 0.2s;
}

.dekra-card.on-hover {
  transform: scale(1.04);
}

.dekra-error-card {
  border-bottom: 5px solid #f44336;
}
</style>
